import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import requestIdleCallback from 'lib/requestIdleCallback'
import { useWindowSize } from '@react-hook/window-size'

import classNames from 'classnames/bind'

import useUIContext from 'context/ui'

import { CURSOR_ICONS } from 'components/ui/Cursor'
import SplitText from 'components/motion/SplitText'
import { Container, Section } from 'components/layout'
import { Headline2 } from 'components/ui/Typography'
import Button from 'components/ui/Button'
import CursorHover from 'components/ui/CursorHover'
import VideoLightbox from 'components/ui/VideoLightbox'

import s from './HeroSection.module.scss'
const cn = classNames.bind(s)

const _ = {
  positioningDelay: 1000,
}

const HeroSection = ({ title, videoTrigger, videoEmbedID }) => {
  const [inView, setInView] = useState(false)

  const toggleHiddenHeader = useUIContext((s) => s.toggleHiddenHeader)
  const setCursorIcon = useUIContext((s) => s.setCursorIcon)
  const [viewportWidth] = useWindowSize({ initialWidth: 0 })
  const [isLightboxVisible, setIsLightboxVisible] = useState(false)
  const btnRef = useRef(null)
  const splitRef = useRef(null)
  const wrapperRef = useRef(null)
  const isPageTransitioning = useUIContext((s) => s.isPageTransitioning)

  // Position the Video Trigger
  useEffect(() => {
    if (!splitRef.current || !wrapperRef.current) return
    const lastWord = [...splitRef.current.querySelectorAll('[class*=line]')].pop()
    Object.assign(btnRef.current.style, {
      visiblity: 'hidden',
      opacity: 0,
      pointerEvents: 'none',
    })
    setTimeout(() => {
      if (!btnRef.current) return
      const r = lastWord.getBoundingClientRect()
      const offset = r.left + r.width
      Object.assign(btnRef.current.style, {
        left: `${offset}px`,
        visiblity: 'visible',
        opacity: 1,
        pointerEvents: 'auto',
      })
    }, _.positioningDelay)
  }, [btnRef, splitRef, viewportWidth])

  // Lightbox state - SHOW
  const showLightbox = () => {
    setIsLightboxVisible(true)
    window.scrollTo(0, 0)

    requestIdleCallback(() => {
      toggleHiddenHeader(true)
      window.addEventListener('scroll', hideLightbox, { once: true })
    })
  }

  // Lightbox state - HIDE
  const hideLightbox = () => {
    setIsLightboxVisible(false)
    toggleHiddenHeader(false)
    setCursorIcon(null)
    window.removeEventListener('scroll', hideLightbox)
  }

  return (
    <Section className={cn('section')} fullHeight onEnter={() => setInView(true)}>
      <Container>
        <div className={cn('wrapper', { inView, isLightboxVisible, isPageTransitioning })} ref={wrapperRef}>
          <Headline2 className={cn('title')}>
            <SplitText ref={videoTrigger ? splitRef : null}>{title}</SplitText>
            {videoEmbedID ? (
              <CursorHover icon={CURSOR_ICONS.VIDEO_CONTROLS_PLAY}>
                <div className={cn('btn')} ref={btnRef} onClick={showLightbox}>
                  <Button>{videoTrigger}</Button>
                </div>
              </CursorHover>
            ) : (
              <div className={cn('btn', 'idle')} ref={btnRef}>
                <Button>{videoTrigger}</Button>
              </div>
            )}
          </Headline2>
        </div>
      </Container>
      {videoEmbedID && (
        <VideoLightbox embedID={videoEmbedID} isVisible={isLightboxVisible} onHide={hideLightbox} onTop />
      )}
    </Section>
  )
}

HeroSection.propTypes = {
  title: PropTypes.string.isRequired,
  videoTrigger: PropTypes.string,
  videoEmbedID: PropTypes.string,
}

export default HeroSection
